import { useParams } from "react-router-dom";

const buttonStyle = {
  margin: "10px auto",
  width: 200,
  height: 50,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  textDecoration: "none",
  color: "rgba(0, 0, 0, 0.6)",
  fontWeight: 600,
  borderRadius: 10,
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
};

const TELEGRAM_URL =
  process.env.REACT_APP_TELEGRAM_URL ||
  "https://t.me/The_Oct_Game_Bot/OCT_game";
const APP_STORE_URL =
  process.env.REACT_APP_APP_STORE_URL ||
  "https://testflight.apple.com/join/5FLmfjrt";
const GOOGLE_PLAY_URL =
  process.env.REACT_APP_GOOGLE_PLAY_URL ||
  "https://static.theoct.xyz/android/TheOct%20(58).apk";

export const InvitePage = () => {
  const { rid } = useParams();

  if (!rid) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        alt={""}
        src={
          "https://ugc.production.linktr.ee/9c71810f-d4eb-4917-afef-b64fd9e3cd2f_iOS-Icon.png?io=true&size=avatar-v3_0"
        }
        style={{
          width: 96,
          height: 96,
          borderRadius: "50%",
          display: "block",
          marginBottom: 10,
        }}
      />
      <a style={buttonStyle} href={`${TELEGRAM_URL}?startapp=rid-${rid}`}>
        Play in Telegram
      </a>
      {/* <a style={buttonStyle} href={`${APP_STORE_URL}`}>Install beta on iOS</a>
    <a style={buttonStyle} href={`${GOOGLE_PLAY_URL}`}>Install beta on Android</a> */}
    </div>
  );
};
