import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API } from '../api';

export const TwitterPage = () => {
  const [query] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const oauthToken = query.get('oauth_token');
    const oauthVerifier = query.get('oauth_verifier');

    if (oauthToken && oauthVerifier) {
      setIsLoading(true);
      setIsError(false);
      API.post('/v1/social/bind-twitter', { oauthToken, oauthVerifier })
        .then(() => setIsSuccess(true))
        .catch(() => setIsError(true));
      setIsLoading(false);
    }
  }, [query]);

  return <div style={{ display: 'flex', width: '100%', flexDirection: 'column', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
    {isLoading && <div>Loading...</div>}
    {isSuccess && <div>You successfully subscribed on our Twitter</div>}
    {isError && <div>Error</div>}
  </div>;
}