import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { InvitePage } from './pages/InvitePage';
import { TwitterPage } from './pages/TwitterPage';

const AppContent = () => {
  return <div>
    <Routes>
      <Route path={"/invite/:rid"} element={<InvitePage/>}/>
      <Route path={"/twitter"} element={<TwitterPage/>}/>
    </Routes>
  </div>;
}

function App() {
  return <BrowserRouter>
    <AppContent/>
  </BrowserRouter>;
}

export default App;
